export class Utils {
  static convertToWebp(event: any) {
    //console.log('convert', event + '.webp')
    // return event ? URL.createObjectURL(event) : "default.png";

    if (event) {
      return event + '.webp'
    }
  }

  public static getLogo(type: any) {
    switch(type){
      case 'OFFICE365':
        return 'assets/images/connectors/office365.svg';
      case 'SPOTLIGHT':
        return 'assets/images/connectors/SpotLight.svg';
      case 'SHAREPOINT':
        return 'assets/images/connectors/SharePoint.png'
    }
  }
}